<template>
  <OnBoarding>
    <template>
      <PTransparentLoader :loading="loading">
        <CRow>
          <CCol>
            <OnBoardingHeader :title="$gettext('Accept invitation')"/>

            <CAlert show v-if="invalidCode" color="danger">
              <translate>Invalid Code</translate>
            </CAlert>

            <CRow class="justify-content-center align-items-center text-center" v-if="!invalidCode">
              <CCol col="12">
                <div class="font-weight-medium"><translate>You have been invited by:</translate></div>
                <div class="mb-2">{{ invitation.created_by.last_name }} {{ invitation.created_by.first_name }}</div>

                <template v-if="invitation.message">
                  <div class="font-weight-medium"><translate>Message:</translate></div>
                  <div class="mb-2">{{ invitation.message}}</div>
                </template>

                <template v-if="invitation.pica_service">
                  <div class="font-weight-medium"><translate>For Pica Service:</translate></div>
                  <div class="mb-2">{{ invitation.pica_service}}</div>
                </template>
                <div class="font-weight-medium"><translate>To participate with the role of:</translate></div>
                <div class="mb-3">{{ invitation.role.name }} {{ invitation.role.description }}</div>
              </CCol>
              <CCol col="12">
                <AcceptLogged v-if="isAuthenticated" @accept="onAccept"/>
                <AcceptNotLogged v-else/>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </PTransparentLoader>
    </template>
  </OnBoarding>
</template>

<script>

import TheFooter from "@/containers/TheFooter.vue";
import AcceptLogged from "@/domain/login/components/AcceptLogged.vue";
import AcceptNotLogged from "@/domain/login/components/AcceptNotLogged.vue";
import PTransparentLoader from "@/domain/core/components/PTransparentLoader.vue";
import {computed, methods} from "@/domain/login/mixin";
import OnBoarding from "@/domain/login/OnBoarding.vue";
import OnBoardingHeader from "@/domain/login/components/OnBoardingHeader.vue";

export default {
  name: 'Accept',
  components: {OnBoardingHeader, OnBoarding, PTransparentLoader, AcceptNotLogged, AcceptLogged, TheFooter},
  async mounted() {
    await this.checkQueryParams()

    // The accept page is only for user with an active invitation or that at least passed some parameter in the url
    if (!this.invitation && !this.$route.query.code && !this.$route.query.type)
      await this.$router.push({name: 'login'})
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...computed,
    invalidCode() {
      return !this.invitation
    }
  },
  methods: {
    ...methods,
    async onAccept() {
      const invitation = await this.acceptInvitation()
      if (invitation.type === 'ps')
        await this.$router.push({name: 'eventIndex', params: {picaServiceId: invitation.id_param}})
      else
        await this.$router.push({name: 'eventList'})
    }
  }
}
</script>
