<template>
  <div class="text-center pt-3">
    <h5 class="font-weight-medium" style="font-size: 16px">
      <translate>Already have an account?</translate>
      <template v-if="allowRegister">
        <CLink :to="{name: 'login_or_register'}" class="ml-2 font-weight-semi-bold">
          <span v-translate>Login</span>
          <CIcon class="ml-2" size="custom-size" :height="20" name="cip-log-out-04"/>
        </CLink>
      </template>
      <template v-else>
        <CLink :to="{name: 'login'}" class="ml-2 font-weight-semi-bold">
          <span v-translate>Login</span>
          <CIcon class="ml-2" size="custom-size" :height="20" name="cip-log-out-04"/>
        </CLink>
      </template>

    </h5>
  </div>
</template>
<script>
export default {
  name: "AlreadyHaveAccount",
  props: {
    allowRegister: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

</style>